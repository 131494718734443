import React from "react";
import { PageLayout, Background } from "@bluefin/components";
import { Grid, Image } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";

export default class MerchandisePage extends React.PureComponent {
  render() {
    return (
      <WebsiteLayout>
        <PageLayout
          hero={
            <Background
              columns={1}
              stackable={true}
              className={"merchandise-hero"}
            />
          }
          subfooter={false}
          className={"merchandise-page"}
        >
          <div className={"merchandise-item"}>
            <h4>Kees Kitchen T Shirt</h4>
            <Grid
              stackable={true}
              className={"component-section-container"}
              columns={2}
              textAlign={"center"}
              padded={"vertically"}
            >
              <Grid.Column width={5}>
                <Image
                  size={"medium"}
                  centered={true}
                  src={
                    "https://fisherman.gumlet.com/public/keeskitchen/Photo_4.jpeg"
                  }
                />
              </Grid.Column>
              <Grid.Column width={5}>
                <Image
                  size={"medium"}
                  centered={true}
                  src={
                    "https://fisherman.gumlet.com/public/keeskitchen/Photo_3.jpeg"
                  }
                />
              </Grid.Column>
            </Grid>
          </div>
          <div className={"merchandise-item"}>
            <h4>Kees Kitchen Tank Top</h4>
            <Grid
              stackable={true}
              className={"component-section-container"}
              textAlign={"center"}
              padded={"vertically"}
            >
              <Grid.Column>
                <Image
                  size={"huge"}
                  centered={true}
                  src={
                    "https://fisherman.gumlet.io/public/keeskitchen/Photo_1.jpeg"
                  }
                />
              </Grid.Column>
            </Grid>
          </div>
          <div className={"merchandise-item"}>
            <h4>Kees Kitchen BBQ Sauce</h4>
            <Grid
              stackable={true}
              className={"component-section-container"}
              columns={2}
              textAlign={"center"}
              padded={"vertically"}
              verticalAlign={"middle"}
            >
              <Grid.Column width={6}>
                <Image
                  size={"large"}
                  centered={true}
                  src={
                    "https://fisherman.gumlet.io/public/keeskitchen/image-11-09-20-02-24.jpeg"
                  }
                />
              </Grid.Column>
              <Grid.Column width={10}>
                <Image
                  size={"huge"}
                  centered={true}
                  src={
                    "https://fisherman.gumlet.io/public/keeskitchen/image-11-09-20-02-24-1.jpeg"
                  }
                />
              </Grid.Column>
            </Grid>
          </div>
          <p>Now available! Kees Kitchen T Shirts, Tank Tops and BBQ Sauce!</p>
          <p>
            BBQ Sauce made and bottled in Charleston SC! Brings out the best
            flavor in anything you cook. 16 Oz bottle.
          </p>
          <p>
            BBQ sauce case pricing is available as well! Email
            keeskitchenchs@gmail.com for more information.
          </p>
          <p>
            <a href={"https://www.keeskitchenchs.com/find-store/"}>
              Find a Store
            </a>
            <span dangerouslySetInnerHTML={{ __html: "&nbsp;" }} />
            <span>to see where you can buy our BBQ sauce locally!</span>
          </p>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}
